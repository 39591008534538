import {render} from "react-dom";
import{
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/home/Home"; 
import Login from "./pages/login/Login"; 
import List from "./pages/list/List"; 
import New from "./pages/new/New"; 
import Single from "./pages/single/Single"; 
import PrivateRoute from "./components/privateRoute/PrivateRoute";
function App() {
  
  return (
    
    <div className="App">
    
      <BrowserRouter>
      <Routes>
        <Route path="/">
        <Route index element={<Login/>}/>
        <Route path="home" element={<PrivateRoute><Home /></PrivateRoute>} />

        

        
        {/* <Route path="users">
          <Route index element={<PrivateRoute><List/></PrivateRoute>} />
          <Route path=":userId" element={<Single/>}/>
          <Route path="new" element={<New/>}/>
        </Route> */}
        
        </Route>
      </Routes>
      </BrowserRouter>
    
    </div>
  );
}

export default App;
