import React from 'react';
import{
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function PrivateRoute({ children }) {

    
    const authToken = Cookies.get("token");



    if (!authToken) {
        return 
        <BrowserRouter>
      <Routes>
      <Route path="/"> </Route>
        </Routes>
      </BrowserRouter>;
    }

    return children;
}

export default PrivateRoute;